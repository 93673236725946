import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import WOW from 'wowjs'
import Footer from '../components/Shared/Footer'
import Header from '../components/Shared/Header'
import NavBar from '../components/Shared/Navbar'
import Modals from '../components/Shared/Modals'
import WelcomeModal from '../components/Shared/WelcomeModal'
import 'swiper/swiper.min.css'
import 'animate.css/animate.min.css'
import '../styles/layout.css'

const isProd = process.env.NODE_ENV === 'production'
const branchName = process.env.BRANCH

const Layout = ({
  children,
  headerClasses,
  customHero,
  heroBodyStyle,
  heroBodyClasses,
  heroContainerId = 'maximusHeroID',
  data = null,
  lightMode = false,
  headerDisable = false,
  footerDisable = false,
  noIndex = false,
  signUpUrl = null,
  signUpText = null,
  signInHide = false,
  topBar = null,
  themeColor = '#000000'
}) => {
  useEffect(() => {
    new WOW.WOW({ live: false }).init()
  }, [])

  if (typeof window !== 'undefined' && window.localStorage && window.location.search) {
    window.localStorage.setItem('searchParams', window.location.search)
  }

  return (
    <>
      <Helmet>
        <meta name="facebook-domain-verification" content="iya5akpfz91rabm8tmn69esd7mz8vi" />
        <meta name="google-site-verification" content="CGren4xLhQQQSz6eDZ0YcVtnBFR_Xw1xiKgfwDNVbTY" />
        <meta name="theme-color" content={themeColor} />
        {noIndex && <meta name="robots" content="noindex" />}
      </Helmet>

      {!headerDisable && data.settings && data.settings.navbar && (
        <NavBar
          navbar={data.settings.navbar}
          protocols={data.settings.protocols}
          signUpUrl={signUpUrl}
          signUpText={signUpText}
          signInHide={signInHide}
          topBar={topBar}
        />
      )}

      {(data.hero || data.contacts || customHero) && !lightMode && (
        <Header
          classes={headerClasses}
          hero={data.hero}
          customHero={customHero}
          getStartedNowUrl={data.settings ? data.settings.getStartedNowUrl : ''}
          contacts={data.contacts}
          heroBodyStyle={heroBodyStyle}
          heroBodyClasses={heroBodyClasses}
          heroContainerId={heroContainerId}
        />
      )}

      <main>{children}</main>

      {!footerDisable && data.settings && data.settings.footer && <Footer footer={data.settings.footer} />}

      <Modals />

      {data.settings?.welcomePopup?.enable && <WelcomeModal data={data.settings.welcomePopup} />}

      {isProd && (
        <>
          <script type="text/javascript" charset="UTF-8" src="//cdn.cookie-script.com/s/3e6f07c97c2bf331d6ccc20d9164afd3.js" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              analytics.track('pageview', {
                'Branch': '${branchName}'
              });`,
            }}
          />
        </>
      )}

      <script
        dangerouslySetInnerHTML={{
          __html: `
(function () {
function getCookieValue(name){
  return (document.cookie.match('(^|;)\\\\s*' + name + '\\\\s*=\\\\s*([^;]+)') || []).pop() || ''
}
if (URLSearchParams && Array && Array.from) {
  var params = new URLSearchParams(window.location.search)
  if (Array.from(params.keys()).some(function(t){
    return t.match(/^utm_/)
  })) {
    var cookieName = 'mt_landing_url_first'
    if (getCookieValue(cookieName)){
      cookieName = 'mt_landing_url_last'
    }
    var mainDomain = window.location.host.replace('www.', '.')
    document.cookie = cookieName+"="+window.location.href+";expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;SameSite=None;Secure;domain=" + mainDomain;
  }
} 
})()`,
        }}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
